



















































































































































































import { Vue, Component } from "vue-property-decorator";
import { Messages } from "@/models/enums/messages.enum";
import { masterServices } from "@/services/master.service";
import { settingsServices } from "@/services/settings.service";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import moment from "moment";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import "vue2-datepicker/index.css";
import {
  DataRequestCLosePeriod,
  RequestUpdateClosePeriod,
  ResponseListOfCalendar,
} from "@/models/interface/settings.interface";
import { ColumnTableCustom } from "@/models/interface/util.interfase";
import DatePicker from "vue2-datepicker";
interface ObjectClosePeriod {
  masterTypeId: string;
  closePeriod: boolean;
}
interface CalendarType {
  id: string;
  name: string;
  value: string;
  key?: number;
}
@Component({
  components: {
    DatePicker: DatePicker,
  },
})
export default class Calendar extends Vue {
  startYear = "" as string;
  endYear = "" as string;
  valueDate = [] as string[];
  dataListModul = [] as CalendarType[];
  form = this.$form.createForm(this, { name: "calendar" });
  page = 0 as number;
  limit = 16 as number;
  search = "" as string;
  dataDetails = [] as any;
  sort = "startFrom:asc" as string;
  loadingTable = false as boolean;
  titlemodalOpen = "lbl_calendar" as string;
  modalOpen = false as boolean;
  isFormSubmitted = false as boolean;
  isFormSubmittedGenerate = false as boolean;
  direction = "asc" as string;
  formRules = {
    start: {
      label: "lbl_start_year",
      name: "start",
      placeholder: "lbl_start_year",
      decorator: [
        "start",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    end: {
      label: "lbl_end_year",
      name: "end",
      placeholder: "lbl_end_year",
      decorator: [
        "end",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    moduls: {
      label: "lbl_modules",
      name: "moduls",
      placeholder: "lbl_modules",
      decorator: [
        "moduls",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  };
  columnsTable = [
    {
      title: this.$t("lbl_month"),
      dataIndex: "month",
      key: "month",
      sorter: true,
      width: 100,
    },
    {
      title: this.$t("lbl_year"),
      dataIndex: "year",
      key: "year",
      sorter: true,
      width: 100,
    },
    {
      title: this.$t("lbl_quarter"),
      dataIndex: "quarter",
      key: "quarter",
      sorter: true,
      width: 100,
    },
    {
      title: this.$t("lbl_from"),
      dataIndex: "startFrom",
      key: "from",
      sorter: true,
      width: 100,
    },
    {
      title: this.$t("lbl_to"),
      dataIndex: "endTo",
      key: "to",
      sorter: true,
      width: 100,
    },
    {
      title: this.$t("lbl_close_period"),
      dataIndex: "calendarClosePeriodsDisplay",
      key: "close",
      width: 200,
      scopedSlots: { customRender: "isRenderTag" },
    },
  ] as ColumnTableCustom[];
  dataListRates = {} as ResponseListOfCalendar;
  mounted() {
    this.getListOfMasterType();
    this.getListOfCalendar("");
  }
  created() {
    this.columnsTable.push({
      title: this.$root.$t("lbl_action").toString(),
      dataIndex: "operation",
      key: "operation",
      scopedSlots: { customRender: "operation" },
      button: ["update"],
      width: 120,
      align: "center",
      fixed: "right",
    });
  }
  submitFormEdit(): void {
    const dataPush = [] as DataRequestCLosePeriod[];
    this.dataDetails.forEach(element => {
      const dataObj = {
        calendarClosePeriodId: element.id,
        closePeriod: element.closePeriod,
      };
      dataPush.push(dataObj);
    });
    const dataPost = {
      requestBody: dataPush,
    } as RequestUpdateClosePeriod;
    this.isFormSubmitted = true;
    settingsServices
      .updateCalendarClosePeriod(dataPost)
      .then(() => {
        this.modalOpen = false;
        this.isFormSubmitted = false;
        this.getListOfCalendar("");
        this.$notification.success({
          description: Messages.UPDATE_SUCCESS,
          message: "Success",
          duration: 30,
        });
      })
      .catch(e => {
        this.isFormSubmitted = false;
        this.$notification.error({
          description: Messages.UPDATE_FAIL,
          message: "Error",
          duration: 30,
        });
        this.$notification.error({
          description: e.details,
          message: "Error",
          duration: 30,
        });
      })
      .finally(() => (this.loadingTable = false));
  }
  handleSelectChange(name, data): void {
    this.dataDetails.find(item => item.modulName === name).closePeriod =
      data === "true" ? true : false;
  }
  handleCancel(): void {
    this.modalOpen = false;
  }
  reponseEditTable(response): void {
    this.dataDetails = response.data.calendarClosePeriods;
    this.modalOpen = true;
  }
  responsePageSizeChange(response: ResponsePagination): void {
    this.limit = response.size;
    this.page = 0;
    this.getListOfCalendar("");
  }
  responseCurrentPageChange(response: ResponsePagination): void {
    this.page = response.page - 1;
    this.getListOfCalendar("");
  }
  onSorterChange(response): void {
    this.direction = response.sort.order === "ascend" ? "asc" : "desc";
    if (response.sort.field) this.sort = `startFrom:${this.direction}`;
    else this.sort = `${response.sort.field}:${this.direction}`;
    this.getListOfCalendar("");
  }
  handlePanelChange2(value) {
    this.valueDate = value;
  }
  handleChangeDate(value) {
    this.valueDate = value;
  }

  submitForm(e: Event): void {
    e.preventDefault();
    this.form.validateFields((err, values) => {
      if (!err && this.startYear && this.endYear) {
        const arrObj = [] as ObjectClosePeriod[];
        values.moduls.forEach(element => {
          const obj = {
            masterTypeId: element,
            closePeriod: false,
          };
          arrObj.push(obj);
        });
        const dataPost = {
          start: moment(this.startYear).format("YYYY") + "-01-01T01:00:00+00",
          end: moment(this.endYear).format("YYYY") + "-12-31T23:59:00+00",
          moduls: arrObj,
        };
        this.isFormSubmittedGenerate = true;
        this.loadingTable = true;
        settingsServices
          .createCalendar(dataPost)
          .then(() => {
            this.isFormSubmittedGenerate = false;
            this.form.resetFields();
            this.startYear = "";
            this.endYear = "";
            this.getListOfCalendar("");
            this.$notification.success({
              description: Messages.CREATE_SUCCESS,
              message: "Success",
              duration: 30,
            });
          })
          .catch(error => {
            this.isFormSubmittedGenerate = false;
            this.$notification.error({
              description: Messages.CREATE_FAIL,
              message: "Error",
              duration: 30,
            });
            this.$notification.error({
              description: error.details,
              message: "Error",
              duration: 30,
            });
          })
          .finally(() => (this.loadingTable = false));
      } else {
        this.$notification["error"]({
          message: "Error",
          description: "Form is mandatory",
        });
      }
    });
  }
  getListOfMasterType(): void {
    let params = {
      name: "CALENDAR_MODULE",
    } as RequestQueryParamsModel;
    masterServices.listMaster(params).then((res: CalendarType[]) => {
      res.forEach((dataObject, index) => (dataObject.key = index));
      this.dataListModul = res;
    });
  }
  getListOfCalendar(path: string): void {
    let params;
    if (!path) {
      params = {
        limit: this.limit,
        page: this.page,
        sorts: `startFrom:asc`,
      } as RequestQueryParamsModel;
      if (this.search) params.search = this.search;
      if (this.direction) params.sorts = this.sort;
    }
    this.loadingTable = true;
    settingsServices
      .listOfCalendar(params, path)
      .then((res: ResponseListOfCalendar) => {
        if (!path) {
          res.data.forEach(element => {
            const dataClose = [] as string[];
            element.calendarClosePeriods.forEach(item => {
              const typeclosePeriod =
                item.closePeriod === true ? "Closed" : "Open";
              const nameClose = item.modulName + "=" + typeclosePeriod + "<br>";
              dataClose.push(nameClose);
              element["calendarClosePeriodsDisplay"] = dataClose
                .join("")
                .toString();
            });
          });
          res.data.forEach((dataObject, index) => (dataObject.key = index));
          this.dataListRates = res;
        }
      })
      .finally(() => (this.loadingTable = false));
  }
  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
}
